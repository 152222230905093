<template>
    <div class="print-page">
        <h1>Bob Esponja - A experiência</h1>
        <div class="projects-list">
            <!-- Render project buttons -->
            <button v-for="project in projects" :key="project._id" @click="fetchMedia(project.projectCode)">
                {{ project.projectCode }}
            </button>
        </div>

        <!-- Display spinner when loading -->
        <div v-if="isLoading" class="spinner">
            Loading...
        </div>

        <!-- Display media for the selected project -->
        <div v-else-if="selectedProject">
            <h2>Selecione o tipo de mídia que deseja imprimir:</h2>

            <!-- Render media -->
            <div class="media-grid">
                <div v-for="media in filteredMedia" :key="media._id" class="media-item"
                    @click="openImageInNewTab(media.data)">
                    <img :src="media.data" :alt="media.mediaName" />
                    <p>{{ media.mediaName }}</p>
                </div>
            </div>

            <!-- Pagination -->
            <div class="pagination">
                <button :disabled="currentPage === 1" @click="changePage(-1)">
                    Previous
                </button>
                <button :disabled="!hasMoreMedia" @click="changePage(1)">
                    Next
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Print",
    data() {
        return {
            projects: [], // List of projects
            selectedProject: null, // Currently selected project code
            selectedProjectName: "", // Name of the selected project
            media: [], // List of media fetched for the selected project
            currentPage: 1, // Current page of pagination
            filterText: "", // Filter text for media names
            hasMoreMedia: true, // Whether there are more media to fetch
            isLoading: false, // Indicates if media is being fetched
        };
    },
    computed: {
        filteredMedia() {
            // Filter and sort media by name and upload date (recent first)
            const filter = this.filterText.toLowerCase();
            return this.media
                .filter((media) => media.mediaName.toLowerCase().includes(filter));
        },
    },
    methods: {
        async fetchProjects() {
            try {
                const response = await fetch("/api/projects/activeProjects");
                this.projects = await response.json();
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        },
        async fetchMedia(projectCode, page = 1) {
            this.isLoading = true; // Show spinner
            try {
                this.selectedProject = projectCode;
                const selectedProject = this.projects.find(project => project.projectCode === projectCode);
                this.selectedProjectName = selectedProject ? selectedProject.projectCode : null;
                this.currentPage = page;

                // Make the API request to fetch project media
                const response = await fetch(
                    `/api/projects/${projectCode}/media?page=${this.currentPage}`
                );
                const data = await response.json();

                this.media = data.media || [];// Update media list
                this.hasMoreMedia = data.hasNextPage;
            } catch (error) {
                console.error("Error fetching media:", error);
            } finally {
                this.isLoading = false; // Hide spinner
            }
        },
        changePage(offset) {
            const newPage = this.currentPage + offset;
            this.fetchMedia(this.selectedProject, newPage);
        },
        openImageInNewTab(imageData) {
            // Open the image in a new tab for printing
            const newTab = window.open();
            if (newTab) {
                newTab.document.body.style.margin = "100";
                newTab.document.body.style.display = "flex";
                newTab.document.body.style.justifyContent = "center";
                newTab.document.body.style.alignItems = "center";
                const img = new Image();
                img.src = imageData;
                img.style.maxWidth = "100%";
                img.style.maxHeight = "100%";
                newTab.document.body.appendChild(img);
            } else {
                console.error("Failed to open new tab");
            }
        },
    },
    mounted() {
        this.fetchProjects();
    },
};
</script>

<style scoped>
.print-page {
    padding: 20px;
}

.projects-list button {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
}

.media-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;
}

.media-item {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    width: 150px;
    cursor: pointer;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    overflow-wrap: break-word;
}

.media-item img {
    max-width: 100%;
    max-height: 100px;
    margin-bottom: 10px;
}

.pagination button {
    margin: 10px;
}

/* Spinner styles */
.spinner {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #555;
    margin: 20px 0;
}
</style>