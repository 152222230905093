<template>
  <div>
    <h1>Login</h1>
    <!-- <form @submit.prevent="login">
      <div>
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="password" id="password" required />
      </div>
      <button type="submit">Login</button>
    </form>
    <p v-if="error">{{ error }}</p> -->
  </div>
</template>

<script>
export default {
  name: "Login",
  //   data() {
  //     return {
  //       username: "",
  //       password: "",
  //       error: null,
  //     };
  //   },
  //   methods: {
  //     async login() {
  //       try {
  //         const response = await this.$http.post("/api/auth/login", {
  //           username: this.username,
  //           password: this.password,
  //         });
  //         const token = response.data.token;
  //         localStorage.setItem("token", token);
  //         this.$router.push("/admin"); // Redirect to the admin page
  //       } catch (error) {
  //         this.error = "Invalid username or password";
  //       }
  //     },
  //   },
};
</script>